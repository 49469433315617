<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#7CB9E8" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog
      v-model="cropImageDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? '100vw'
          : $vuetify.breakpoint.name == 'md'
          ? '80vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '50vw'
          : '40vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <ImageCropper
              :image="currentImage"
              :key="currentImage"
              @stepper="imageCropper"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-img src="./../../assets/images/login.jpg">
      <v-layout
        wrap
        justify-center
        align-center
        :style="{
          height:
            $vuetify.breakpoint.name == 'xs'
              ? '100%'
              : $vuetify.breakpoint.name == 'sm'
              ? '100%'
              : $vuetify.breakpoint.name == 'md'
              ? '100vh'
              : $vuetify.breakpoint.name == 'lg'
              ? '100vh'
              : '100vh',
        }"
      >
        <v-flex xs10 sm10 md8 align-self-center>
          <v-card rounded="xl" elevation="24">
            <v-layout wrap justify-center>
              <v-flex xs12 sm4 md3 lg3 xl2 class="bg">
                <v-layout
                  wrap
                  justify-center
                  align-center
                  :style="{
                    height:
                      $vuetify.breakpoint.name == 'xs'
                        ? '100%'
                        : $vuetify.breakpoint.name == 'sm'
                        ? '100%'
                        : $vuetify.breakpoint.name == 'md'
                        ? '70vh'
                        : $vuetify.breakpoint.name == 'lg'
                        ? '70vh'
                        : '70vh',
                  }"
                >
                  <v-flex class="hidden-sm-and-down">
                    <router-link to="/">
                      <v-layout wrap justify-center align-center pt-6>
                        <v-flex xs1 align-self-center
                          ><v-img
                            src="./../../assets/newecologo.png"
                            height="25px"
                            width="25px"
                          ></v-img
                        ></v-flex>
                        <v-flex xs4 sm8 md8 lg6 align-self-center>
                          <span
                            style="
                              font-family: pollerOneRegular;
                              font-size: 14px;
                              color: black;
                            "
                            >ECOLedger</span
                          >
                        </v-flex>
                      </v-layout>
                    </router-link>
                    <v-layout wrap justify-center pt-8>
                      <v-flex xs12>
                        <span
                          style="font-family: montserratBold; font-size: 22px"
                          >Sign Up
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex class="hidden-md-and-up" py-5>
                    <router-link to="/">
                      <v-layout wrap justify-center align-center>
                        <v-flex xs1 align-self-center
                          ><v-img
                            src="./../../assets/logoeco.png"
                            contain
                            height="25px"
                            width="25px"
                          ></v-img
                        ></v-flex>
                        <v-flex xs4 sm7 align-self-center>
                          <span
                            style="
                              font-family: pollerOneRegular;
                              font-size: 14px;
                            "
                            >ECOLedger</span
                          >
                        </v-flex>
                      </v-layout>
                    </router-link>
                    <v-layout wrap justify-center pt-8>
                      <v-flex xs12>
                        <span
                          style="font-family: montserratBold; font-size: 22px"
                          >Sign Up
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm8 md9 lg9 xl10 pt-12>
                <v-form v-model="reg" ref="profileForm" lazy-validation>
                  <v-layout wrap justify-center>
                    <v-flex xs10 sm5 text-left pr-2>
                      <span
                        style="font-family: montserratRegular; font-size: 14px"
                        >Name</span
                      >
                      <v-text-field
                        outlined
                        dense
                        autofocus
                        :rules="nameRules"
                        v-model="register.name"
                        placeholder="Full Name as specified on your ID card"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs10 sm5 text-left>
                      <span
                        style="font-family: montserratRegular; font-size: 14px"
                        >Email Address</span
                      >
                      <v-text-field
                        outlined
                        dense
                        :rules="emailRules"
                        v-model="register.email"
                        placeholder="Email"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs10 sm5 text-left pr-2>
                      <span
                        style="font-family: montserratRegular; font-size: 14px"
                        >Password</span
                      >
                      &nbsp;
                      <v-tooltip right color="#e1ebe9">
                        <template v-slot:activator="{ on, attrs }"
                          ><v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            style="cursor: pointer"
                            >mdi-information</v-icon
                          >
                        </template>
                        <v-layout wrap>
                          <v-flex xs12 text-left>
                            <span
                              style="
                                font-family: montserratBold;
                                font-size: 12px;
                                color: black;
                              "
                              >Password must contain :</span
                            >
                            <br />
                            <span
                              style="
                                font-family: montserratSemiBold;
                                font-size: 12px;
                                color: black;
                              "
                              >&nbsp; &nbsp; One number</span
                            >
                            <br />
                            <span
                              style="
                                font-family: montserratSemiBold;
                                font-size: 12px;
                                color: black;
                              "
                              >&nbsp; &nbsp; One lowercase letter</span
                            >
                            <br /><span
                              style="
                                font-family: montserratSemiBold;
                                font-size: 12px;
                                color: black;
                              "
                              >&nbsp; &nbsp; One uppercase letter</span
                            >
                            <br /><span
                              style="
                                font-family: montserratSemiBold;
                                font-size: 12px;
                                color: black;
                              "
                              >&nbsp; &nbsp; One special character</span
                            >
                            <br /><span
                              style="
                                font-family: montserratSemiBold;
                                font-size: 12px;
                                color: black;
                              "
                              >&nbsp; &nbsp; Minimum 10 characters</span
                            >
                            <br /><span
                              style="
                                font-family: montserratSemiBold;
                                font-size: 12px;
                                color: black;
                              "
                              >&nbsp; &nbsp; Maximum 15 characters</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-tooltip>

                      <v-text-field
                        outlined
                        dense
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        :rules="passwordRules"
                        v-model="register.password"
                        placeholder="Minimum 14 characters with any character"
                        @click:append="show1 = !show1"
                      ></v-text-field>
                      <span
                        @click="autoGenerate()"
                        style="
                          font-family: montserratSemiBold;
                          font-size: 12px;
                          color: green;
                          cursor: pointer;
                        "
                        >Auto generate a secure password</span
                      >
                    </v-flex>
                    <v-flex xs10 sm5 text-left>
                      <span
                        style="font-family: montserratRegular; font-size: 14px"
                        >Organisation Name</span
                      >
                      <v-text-field
                        outlined
                        :rules="nameRules"
                        v-model="register.organisationName"
                        dense
                        placeholder="Organisation Name"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs10 sm10 text-left pt-5>
                      <span
                        style="font-family: montserratRegular; font-size: 14px"
                        >Identity Number</span
                      >
                      <v-text-field
                        outlined
                        v-model="register.identityNumber"
                        dense
                        :rules="Rules"
                        placeholder="Government issued Identity Number"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs10 sm10 text-left>
                      <span
                        style="font-family: montserratRegular; font-size: 14px"
                        >Upload image of Government issued Identity Card for
                        verification</span
                      >
                      <v-card class="mx-auto" outlined>
                        <v-layout wrap pa-3>
                          <v-flex xs12 md12 lg12>
                            <v-layout wrap>
                              <v-flex lg1> </v-flex>
                              <v-flex
                                xs12
                                sm3
                                v-for="(item, i) in imageArray"
                                :key="i"
                                pa-1
                              >
                                <v-img :src="item" height="50px" contain>
                                  <v-layout wrap>
                                    <v-flex text-right pa-0>
                                      <v-avatar color="#FF3434" size="15">
                                        <v-icon
                                          color="#FFF"
                                          small
                                          @click="removeImageArray(i)"
                                        >
                                          mdi-close
                                        </v-icon>
                                      </v-avatar>
                                    </v-flex>
                                  </v-layout>
                                </v-img>
                              </v-flex>
                            </v-layout>

                            <div class="dottedline"></div>
                            <v-layout wrap pt-1 align-center>
                              <v-flex
                                xs10
                                md6
                                lg12
                                align-self-center
                                text-center
                                pb-2
                              >
                                <v-icon
                                  size="25px"
                                  id="pro_pic"
                                  @click="$refs.files.click()"
                                  >mdi-plus</v-icon
                                >
                                <span
                                  style="
                                    color: #828282;
                                    font-family: montserratLight;
                                    font-size: 14px;
                                  "
                                  >Upload your Image</span
                                >
                                <input
                                  v-show="false"
                                  accept="image/*"
                                  id="file"
                                  ref="files"
                                  type="file"
                                  @change="uploadImages"
                                />
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-center pt-5 pb-12>
                    <v-flex xs10>
                      <v-layout wrap justify-center>
                        <v-flex xs1 sm1 pt-2>
                          <v-checkbox
                            color="#7CB9E8"
                            v-model="checkbox"
                          ></v-checkbox
                        ></v-flex>
                        <v-flex xs10 sm8 text-left>
                          <span
                            style="
                              font-family: montserratRegular;
                              font-size: 12px;
                            "
                            >Please confirm that all supplied information is
                            true and accurate. By clicking the Sign Up button
                            you also agree to our
                            <span
                              style="
                                font-family: montserratBold;
                                font-size: 12px;
                                color: #6aafee;
                              "
                            >
                              <router-link to="/termsAndConditions">
                                Terms and Conditions.</router-link
                              ></span
                            >
                          </span>
                        </v-flex>
                        <v-flex xs6 sm3>
                          <v-btn
                            v-if="checkbox"
                            rounded
                            block
                            color="#7CB9E8"
                            dark
                            :disabled="!reg"
                            @click="validate()"
                          >
                            <span
                              style="
                                font-size: 12px;
                                font-family: montserratSemiBold;
                              "
                              >SIGN UP</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-img>
  </div>
</template>
<script>
import axios from "axios";
import ImageCropper from "./../Common/imageCropper";
export default {
  components: {
    ImageCropper,
  },
  data() {
    return {
      show1: false,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      msg: null,
      timeout: 5000,
      imageArray: [],
      Images: new FormData(),
      formData: new FormData(),
      selectedFiles: null,
      currentImage: "",
      cropImageDialog: false,
      image: null,
      checkbox: false,
      reg: false,
      register: {
        name: "",
        email: "",
        password: "",
        organisationName: "",
        identityNumber: "",
      },
      Rules: [(v) => !!v || "Required"],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 20) || "Name must be less than 15 characters",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => /^(?=.*[0-9])/.test(v) || "Password must contain one number",
        (v) =>
          /^.{10,15}$/.test(v) ||
          "Password must contain minimum 10 characters or maximum 15 characters",
        (v) =>
          /^(?=.*[!@#$%^&*])/.test(v) ||
          "Password must contain one special character",
        (v) =>
          /^(?=.*[a-z])/.test(v) ||
          "Password must contain one lowercase letter",
        (v) =>
          /^(?=.*[A-Z])/.test(v) ||
          "Password must contain one uppercase letter",
      ],
      emailRules: [
        (v) => !!v || "Email is Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    validate() {
      if (this.$refs.profileForm.validate()) {
        this.addRegister();
      }
    },
    imageCropper(payload) {
      this.cropImageDialog = payload.dialog;
      if (payload.image) {
        this.collectImages(payload.image);
      }
    },
    addRegister() {
      this.appLoading = true;
      console.log("id", this.selectedFiles);
      // this.formData.append("identityProof", this.selectedFiles);
      this.formData.append("name", this.register.name);
      this.formData.append("email", this.register.email);
      this.formData.append("password", this.register.password);
      this.formData.append("organisationName", this.register.organisationName);
      this.formData.append("identityNumber", this.register.identityNumber);

      axios({
        method: "POST",
        url: "/user/signup",
        data: this.formData,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.formData = new FormData();
            this.selectedFiles = [];
            this.$router.push("/checkEmail?email=" + this.register.email);
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    autoGenerate() {
      axios({
        method: "GET",
        url: "/password/random",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            if (response.data.status) {
              //
              this.password = response.data.password;
              this.register.password = this.password;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    removeImageArray(i) {
      var values = this.formData.getAll("photos");
      values.splice(i, 1);
      this.formData.set("photos", values);
      this.imageArray.splice(i, 1);
    },
    uploadImages(event) {
      if (this.imageArray.length < 4) {
        this.currentImage = URL.createObjectURL(event.target.files[0]);
        this.cropImageDialog = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    collectImages(image) {
      this.selectedFiles = image;
      this.Images.append("image", this.selectedFiles);
      this.formData.append("identityProof", this.selectedFiles);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.imageArray.push(urls);
        if (this.imageArray) {
          this.image = this.imageArray[0];
        }
      };
      console.log("image", this.selectedFiles);
    },
  },
};
</script>
<style>
.bg {
  background: linear-gradient(
    180deg,
    rgba(106, 238, 159, 0.5) 0%,
    rgba(124, 185, 232, 0.5) 72.82%
  );
  border-radius: 20px 0px 0px 20px;
  box-shadow: 7px 0px 10px #dcddde;
}
</style>
